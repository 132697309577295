@use '~@/assets/styles/mixins' as *;

.dashboard {
  display: grid;
  grid-template-areas:
    'score score'
    'defi defi'
    'tradfi personal';
  grid-template-columns: repeat(2, 1fr);
  row-gap: 32px;
  column-gap: 32px;

  @include tablet-down {
    grid-template-areas:
      'score score'
      'defi defi'
      'tradfi tradfi'
      'personal personal';
  }
}

.scoreCard {
  grid-area: score;

  @include phone-only {
    padding: 10px;
  }
}

.personalScoreCard {
  grid-area: personal;
}

.tradFiScoreCard {
  grid-area: tradfi;
}
