@use '~@/assets/styles/mixins' as *;

.programContainer {
  display: flex;
  justify-content: space-between;
  font-family: Roboto, sans-serif;
  transition: .3s ease-in-out;
  padding: 10px;
  border-radius: 10px;
  background: #00000024;
  border: 1px solid #c1c1c1;
  margin-bottom: 8px;
}

.emptyText {
  font-size: 16px;
  font-family: Roboto, sans-serif;
  display: flex;
  text-align: center;
  max-width: 300px;
  margin: auto;
}

.requiredText {
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  align-items: center;

  .description {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 300px;
  }
}

.textWithGap {
  margin-bottom: .5rem;
}

.loader {
  margin: auto;
}

.rightPull {
  display: flex;
  background: white;
  border-radius: 10px;
  align-items: center;
  justify-content: center;
  color: darkslategrey;
  padding: 10px 20px;
  gap: 16px;

  .rightPullItem {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    .text {
      margin-left: 6px;
    }

    @include phone-only {
      background: white;
      padding: 6px 10px;
      border-radius: 12px;
    }
  }

  @include phone-only {
    padding: 0;
    gap: 10px;
    margin-top: 1rem;
    background: transparent;
    width: 100%;
  }
}

.title {
  font-size: 18px;
  font-weight: 600;
  color: white;
}

.qrContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.qrTitle {
  color: #014856;
  max-width: 200px;
  text-align: center;
  margin-bottom: 1rem;
  font-weight: 600;
}

.subtitle {
  display: flex;
  justify-content: center;
  width: 100%;
  color: #014856;
  align-items: center;
  margin-bottom: 1rem;
}

.copy {
  display: flex;
  align-items: center;
  cursor: pointer;

  img {
    margin-right: 6px;
  }
}

.control {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2rem;
  color: #014856;

  @include phone-only {
    flex-direction: column;
  }
}

.points {
  font-weight: 400;
  font-size: 18px;
}

.name {
  font-size: 18px;
}

.mintButton {
  margin-top: 1rem;
}
