@use '~@/assets/styles/mixins' as *;

.wrapper {
  background: rgba(#1c1c1c, 0.6);
  backdrop-filter: blur(10px);
  border-radius: 16px;
  padding: 48px;
  box-sizing: border-box;

  @include phone-only {
    padding: 20px 15px;
  }
}
