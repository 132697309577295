@use '~@/assets/styles/mixins' as *;

.bg {
  width: 100vw;
  height: 100vh;
  position: fixed;
  background-image: url('~@/images/bg.svg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  overflow: auto;

  .layout {
    min-height: 100vh;
    max-width: 1440px;
    margin: 0 auto;
    padding: 48px;
    box-sizing: border-box;
    display: grid;
    grid-template-areas:
      'header title title title profile profile'
      'nav content content content content content';
    grid-template-columns: repeat(6, 1fr);
    grid-auto-rows: min-content;
    column-gap: 24px;
    row-gap: 56px;

    @include tablet-down {
      padding: 48px 32px;
      grid-template-areas:
        'header header profile profile profile profile'
        'title title title title title title'
        'nav content content content content content';
    }
    @include phone-only {
      padding: 48px 24px;
      grid-template-columns: auto;
      grid-template-areas:
        'header'
        'title'
        'content';
    }

    .header {
      display: flex;
      grid-area: header;

      @include phone-only {
        justify-content: space-between;
      }

      .homeLink {
        display: flex;
        align-items: center;

        .logo {
          width: 28px;
          height: 28px;
        }

        .name {
          font-weight: 900;
          font-size: 26px;
          line-height: 31px;
          margin-left: 12px;
        }

        + div {
          display: flex!important;
          align-items: center!important;
        }

        @include phone-only {
          order: 1;
          margin-left: 20px;
        }
      }
    }

    .title {
      display: flex;
      align-items: center;
      font-weight: 600;
      font-size: 24px;
      line-height: 28px;
      grid-area: title;

      @include phone-only {
        justify-content: center;
        text-align: center;
      }
    }

    .profile {
      grid-area: profile;
      display: flex;
      justify-content: flex-end;

      @include phone-only {
        display: none;
      }
    }

    .navigation {
      grid-area: nav;
      display: flex;

      @include phone-only {
        display: none;
      }
    }

    .content {
      grid-area: content;
    }
  }
}

.burgerMenu {
  display: none!important;
  flex-direction: column!important;
  align-items: flex-start !important;

  nav ul div {
    display: flex;
    flex-direction: column;
    gap: 3rem;
  }

  @include phone-only {
    display: flex!important;
  }
}

.burgerMenu a {
  color: white;
  margin: 0 !important;
  margin-bottom: 1rem !important;
  font-size: 20px;
}

.signErrorContainer {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .buttons {
    display: flex;
    flex-direction: row;
    gap: 1rem;
  }

  .button {
    gap: 1rem;
    margin-top: 1rem;
  }
}

.signErrorContent {
  max-width: 320px;
  text-align: center;
  font-size: 22px;
  display: flex;
  flex-direction: column;
  align-items: center;
  line-height: 140%;

  .title {
    font-weight: bold;
    display: flex;
    align-items: center;

    .loader {
      margin-left: .8rem;
    }
  }

  .description {
    margin-top: 1rem;
  }
}

.logoContainer {
  display: flex;
  align-items: center;
  position: absolute;
  left: 2rem;
  top: 2rem;

  .logo {
    width: 50px;
    height: 50px;
  }

  .name {
    font-weight: 900;
    font-size: 26px;
    line-height: 31px;
    margin-left: 1.5rem;
  }
}
