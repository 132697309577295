@use '~@/assets/styles/mixins' as *;

.center {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.text {
  color: #b5b5b5;
  font-size: 16px;

  @include tablet-down {
    align-items: center;
  }
}

.connectButton {
  margin: 0 10px;

  &:hover {
    text-decoration: none;
    cursor: pointer;
  }
}

.connectButton div:first-child button {
  background: transparent;
  border: 1px solid gray;
}

.content {
  display: flex;
  justify-content: space-between;
  align-items: center;

  @include tablet-down {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  .text {
    display: flex;
    flex-direction: column;

    @include phone-only {
      width: 100%;
    }

    .title {
      order: -1;
      display: block;
      font-weight: 700;
      font-size: 16px;
      line-height: 19px;
      margin-bottom: 16px;
      color: white;
    }

    .paragraph {
      font-weight: 300;
      font-size: 16px;
      line-height: 150.02%;

      @include tablet-down {
        order: -1;
        max-width: 80%;
      }
    }
  }
}

.score {
  font-weight: 700;
  font-size: 28px;
  line-height: 44px;
  color: #ffffff;
  margin-bottom: 20px;

  @include phone-only {
    order: -1;
  }
}

.hint {
  margin-top: 24px;
  font-weight: 300;
  font-size: 20px;
  line-height: 24px;
  color: #ffffff;
}

.date {
  margin-top: 32px;
  font-weight: 700;
  font-size: 11px;
  line-height: 1;
  color: #ababab;
}

.action {
  display: flex;
  align-items: center;
  justify-content: space-between;

  @include tablet-down {
    width: 100%;
    margin-left: 0;
    margin-top: 10px;
    justify-content: center;
    flex-direction: column;
  }

  .button {
    display: flex;
    white-space: nowrap;
    margin-left: 32px;

    @include tablet-down {
      margin-left: 0;
      margin-top: 10px;
    }

    .loader {
      margin-left: 16px;
    }
  }
}

.scoreIcon {
  width: 116px;
  height: 116px;
  border-radius: 26px;
}

.openseaButton {
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  align-items: center;
  max-width: 100px;
  border-radius: 9px;
  margin-right: 30px;
  padding: 10px;
  transition: 0.2s;

  @include tablet-down {
    margin-right: 0;
  }
}

.connectText,
.afterSlashText {
  font-size: 20px;
}

.connectText {
  display: flex;
  align-items: center;
}

.input {
  display: flex;
  font-weight: 700;
  font-size: 36px;
  line-height: 19px;
  color: white;
  background: transparent;
  border: none;
  outline: none;
  width: 100%;
}

.editable {
  border: 2px solid #505050;
  border-radius: 5px;
  padding: 4px 10px;
}

.error {
  border-radius: 5px;
  padding: 4px 10px;
  border: 2px solid #505050;
}

.errorHint {
  font-size: 16px;
  color: #ff5050;
  margin-bottom: 10px;

  @include tablet-down {
    order: 1;
  }
}

.pistisSoon {
  @include tablet-down {
    margin-top: 1rem;
    max-width: 200px;
  }

  img {
    max-width: 400px;
    width: 100%;
  }
}
