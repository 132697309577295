.programContainer {
  display: flex;
  justify-content: space-between;
  font-family: Roboto, sans-serif;
  cursor: pointer;
  transition: .3s ease-in-out;
  padding: 10px;
  border-radius: 10px;

  &:hover {
    background: rgba(0, 0, 0, 0.24);
  }
}

.noAccessText {
  font-size: 16px;
  font-family: Roboto, sans-serif;
}

.rightPull {
  display: flex;
  background: white;
  border-radius: 10px;
  align-items: center;
  justify-content: center;
  color: darkslategrey;
  padding: 10px 20px;
  gap: 16px;

  .rightPullItem {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    .text {
      margin-left: 6px;
    }
  }
}

.title {
  font-size: 18px;
  font-weight: 600;
  color: white;
}

.qrContainer {
  display: flex;
}

.loader {
  margin: auto;
}

.content {
  background: white;
  color: #014856;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  padding: 19px 20px;
  width: 100%;
  justify-content: center;
  align-items: center;
}

.changeScoreContainer {
  margin-top: 1rem;
  width: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
}

.hint {
  margin-top: .5rem;
  color: #019095;
}

.input {
  max-width: 228px;
  width: 100%;
  height: 34px;
  border: none;
  outline: none;
  border-bottom: 1px solid gray;
  font-size: 16px;
  padding-bottom: 3px;
}

.button {
  color: #014856;
  border-color: #014856;
  margin-top: 1rem;
}

.points {
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 48px;
  line-height: 48px;
  text-align: center;
  letter-spacing: -0.03em;
  color: #019095;
}

.qrTitle {
  color: #014856;
  max-width: 200px;
  text-align: center;
  margin-bottom: 1rem;
  font-weight: 600;
}

.subtitle {
  display: flex;
  justify-content: center;
  width: 100%;
  color: white;
  align-items: center;
  margin-bottom: 1rem;

  img {
    max-width: 80px;
    width: 100%;
    border-radius: 20%;
  }
}

.copy {
  display: flex;
  align-items: center;
  cursor: pointer;

  img {
    margin-right: 6px;
  }
}

.control {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2rem;
  color: #014856;
}

.name {
  font-size: 22px;
}
