.ldsDualRing {
  display: inline-block;
  width: 80px;
  height: 80px;
  margin: auto auto 26px;
}

.ldsDualRing::after {
  content: ' ';
  display: block;
  width: 64px;
  height: 64px;
  border-radius: 50%;
  border: 6px solid #565656;
  border-color: #565656 transparent;
  animation: lds-dual-ring 1.2s linear infinite;
}

@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.modalContentContainer {
  max-width: 400px;
  .header {
    text-align: center;
    font-weight: 900;
    font-size: 20px;
  }

  .description {
    text-align: center;
    margin-top: 24px;
  }

  .buttons {
    margin-top: 32px;
    display: flex;
    justify-content: center;
    gap: 24px;
  }
}
