@use '~@/assets/styles/mixins' as *;

.navigation {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-width: 208px;

  .nav {
    @include phone-only {
      display: flex;
      justify-content: center;
    }

    .list {
      margin: 0;
      padding-left: 24px;

      @include phone-only {
        padding-left: 0;
      }

      .top,
      .bottom {
        .link {
          display: block;
          margin-top: 32px;
          color: #dddddd;
          transition: all 250ms ease-in-out;

          &:first-child {
            margin-top: 0;
          }

          &:hover {
            color: #fff;
          }

          &.active {
            color: #f69400;
          }

          .listItem {
            display: flex;
            list-style: none;
            align-items: center;

            .icon {
              width: 24px;
              height: 24px;
            }

            .title {
              margin-left: 18px;
              font-weight: 500;
              font-size: 18px;
              line-height: 22px;
            }
          }
        }
      }

      .bottom {
        margin-top: 64px;
      }
    }
  }

  .termsLink {
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
      .title {
        color: #fff;
      }
    }

    .icon {
      width: 24px;
      height: 24px;
    }

    .title {
      margin-left: 18px;
      font-weight: 500;
      font-size: 18px;
      line-height: 22px;
      color: #dddddd;
      transition: all 250ms ease-in-out;
    }
  }
}
