@use '~@/assets/styles/variables' as *;

@mixin phone-only {
  @media screen and (max-width: $bp-sm) {
    @content;
  }
}
@mixin tablet-up {
  @media screen and (min-width: $bp-sm) {
    @content;
  }
}
@mixin tablet-only {
  @media screen and (min-width: $bp-sm) and (max-width: $bp-md) {
    @content;
  }
}
@mixin tablet-down {
  @media screen and (max-width: $bp-md) {
    @content;
  }
}
@mixin desktop-up {
  @media screen and (min-width: $bp-md) {
    @content;
  }
}
