.container {
  display: flex;
  justify-items: center;
  align-items: center;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: #ffc107;
  color: #000000;
  height: 26px;
}

.content {
  display: flex;
  width: 100%;
  justify-content: center;
  padding: 4px 10px;
  font-weight: bolder;
}
