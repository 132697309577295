.button {
  font-weight: 900;
  font-size: 16px;
  line-height: 19px;
  padding: 12px 24px;
  box-sizing: border-box;
  color: #fff;
  border-radius: 12px;
  border: none;
  outline: none;
  background-size: 200% 100%;
  transition: all 0.4s ease-in-out;
  box-shadow: 0 0 20px 0 rgba(255, 245, 0, 0.68);
  background-image: linear-gradient(to right, #f79400, #e0d700, #f79400, #fff944);

  &:hover {
    background-position: 100% 0;
  }

  &:hover {
    cursor: pointer;
  }
}

.primary {
  box-shadow: 0 0 20px 0 rgba(255, 245, 0, 0.68);
  background-image: linear-gradient(to right, #f79400, #e0d700, #f79400, #fff944);
}

.outlined {
  color: white;
  border: 1px solid white;
  box-shadow: none;
  background: transparent none;
}
