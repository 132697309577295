.closeButton {
  background: black;
  box-shadow: none;
}

.loader {
  margin-left: 16px;
}

.actionButtonContainer {
  display: flex;
}

.descriptionContent {
  display: flex;
  flex-direction: column;
}

.descriptionItem {
  text-align: left;
  margin-bottom: 1rem;
}
